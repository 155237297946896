import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { withSentryRouting } from '@sentry/react';
import loadable from '@loadable/component';
import Lobby from 'components/Lobby';
import FullscreenLoading from 'components/Loading/Fullscreen';

const getRedirectPath = (search) => new URLSearchParams(search).get('redirect');

// Parameterized transaction names
const SentryRoute = withSentryRouting(Route);

const SlotRoom = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "slots" */ 'components/Slots'),
  {
    fallback: <FullscreenLoading />
  }
);
const Bingo =
  __ENV__.MRQ_BINGO_ENABLED === 'true'
    ? loadable(
        () => import(/* webpackPrefetch: true, webpackChunkName: "bingo" */ 'components/Bingo'),
        {
          fallback: <FullscreenLoading />
        }
      )
    : null;
const Account = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "account" */ 'components/Account'),
  {
    fallback: <FullscreenLoading />
  }
);

const AppRoutes = ({ search }) => (
  <Switch>
    {search.includes('redirect=') ? <Redirect from="*" to={getRedirectPath(search)} /> : null}
    <Route path="/secure/lobby" component={Lobby} />
    <SentryRoute exact path="/secure/games/:refName" component={SlotRoom} />
    {__ENV__.MRQ_BINGO_ENABLED === 'true' && (
      <SentryRoute path="/secure/bingo/:type/:refName" component={Bingo} />
    )}
    <Route path="/secure/user" component={Account} />
    <Redirect exact from="*" to={`/secure/lobby${search}`} />
  </Switch>
);

export default memo(AppRoutes);
