import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { FormProvider } from 'react-hook-form';
import Modals from 'modules/Modals';
import BottomSheet from 'components/BottomSheet';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { experiments, getExperiment } from 'lib/analytics';
import { useUpdateAccountMarketingPreferences } from 'components/Account/components/AccountMarketingPreferences';
import User from 'modules/User';

const MarketingPreferencesModal = loadable(() => import('./MarketingPreferencesModal'));

export const MarketingPreferencesModalAdapter = () => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { methods, submitFormData } = useUpdateAccountMarketingPreferences();

  const isVerticalMarketingPreferencesModalEnabled = Boolean(
    getExperiment(experiments.verticalMarketingPreferencesModal.flag)?.key
  );

  const hasConsentedToMarketingPreferences: boolean = useAppSelector(
    User.selectors.getConsentToMarketingPreferences
  );

  useEffect(() => {
    if (
      isVerticalMarketingPreferencesModalEnabled &&
      hasConsentedToMarketingPreferences === false
    ) {
      dispatch(Modals.actions.open('verticalMarketingPreferences'));
    }
  }, [isVerticalMarketingPreferencesModalEnabled, hasConsentedToMarketingPreferences, dispatch]);

  const onSubmitClick = () => {
    void (async () => {
      setIsSubmitting(true);
      try {
        await submitFormData();
        dispatch(Modals.actions.close('verticalMarketingPreferences'));
        setIsSubmitting(false);
      } catch (err) {
        setIsSubmitting(false);
      }
    })();
  };

  return (
    <div>
      <BottomSheet name="verticalMarketingPreferences">
        <FormProvider {...methods}>
          <MarketingPreferencesModal isSubmitting={isSubmitting} onSubmitClick={onSubmitClick} />
        </FormProvider>
      </BottomSheet>
    </div>
  );
};
