import { merge, omit, isEmpty, some, isNil } from 'lodash';
import update from 'update-immutable';
import { handleActions } from 'redux-actions';
import * as Router from 'modules/Router';
import { T } from './actionTypes';
import { NAME } from './constants';
import { initialState, defaultModal } from './model';

const createModal = (state, action) => {
  const { name } = action.payload;
  if (isEmpty(state[name])) {
    return update(state, {
      [name]: {
        $set: merge({}, defaultModal, omit(action.payload, 'className'))
      }
    });
  } else {
    return state;
  }
};

const destroyModal = (state, action) => {
  const { name } = action.payload;
  if (isEmpty(state[name])) {
    return state;
  } else {
    return update(state, {
      $unset: name
    });
  }
};

// TODO: Consolidate and simplify modal routing MRQ-1167
const locationChange = (state, action) => {
  const location = action.payload?.location;

  if (!location?.search) {
    return state;
  }

  const searchParams = new URLSearchParams(location.search);
  const modalNames = searchParams.getAll('modals');

  if (isEmpty(modalNames) && !some(state, { visible: true, blocking: true, pinned: false })) {
    return state;
  }

  const result = {};
  Object.keys(state).forEach((name) => {
    const modal = state[name];
    // If closeOnNavigation is explicitely true, makeModal() will forcefully close it
    // If closeOnNavigation is not explicitely prohibited, default to closing unpinned, blocking modals
    if (
      (modal.blocking && !modal.pinned && modal.closeOnNavigation !== false) ||
      modal.forceDeepLink
    ) {
      // Be aware. This make modals disappear if other modals displayed in parallel change the URL
      // by auto-closing themselves or auto-opening other modals.
      // The effect might not be intended but can happen if multiple modals are displayed in the same time
      // and some of them change the URL even without the user interaction.
      // Ex: Deposit switching itself to DepositLimitSolicitations will close a dynamic Dialog
      // if it that dynamic Dialog happened to be there at that moment and does not have closeOnNavigation false.
      result[name] = {
        visible: {
          $set: Array.isArray(modalNames) ? modalNames.includes(name) : modalNames === name // names can be Array || String, THANKS JAVASCRIPT 🤦!!
        }
      };

      // Preserve existing modalParams while updating values from searchParams
      const modalParams = {
        ...modal.modalParams,
        ...Object.fromEntries(
          Object.entries(modal.modalParams || {})
            .map(([key, value]) => [key, searchParams.get(key) ?? value])
            .filter(([, val]) => val !== null)
        )
      };

      if (Object.keys(modalParams).length > 0) {
        result[name].modalParams = { $set: modalParams };
      }
    }
  });
  return update(state, result);
};

const moduleReducer = handleActions(
  {
    [T.UPDATE]: (state, action) =>
      !isEmpty(omit(action.payload, 'name'))
        ? update(state, {
            [action.payload.name]: {
              $merge: omit(action.payload, 'name')
            }
          })
        : state,
    [T.OPEN]: (state, action) => {
      const extraData = omit(action.payload, 'name');
      return !isEmpty(extraData)
        ? update(state, {
            [action.payload.name]: {
              $merge: extraData,
              visible: { $set: true }
            }
          })
        : update(state, {
            [action.payload.name]: {
              visible: { $set: true }
            }
          });
    },
    [T.CLOSE]: (state, action) => {
      const payload = action.payload;
      const { name } = payload;
      const modal = state[name];
      if (modal) {
        const { notification, destroyOnClose } = modal;
        if (destroyOnClose || notification) {
          return destroyModal(state, action);
        }
      }

      return state[name]?.visible // check existence, otherwise could resurrect old or non-existent modal
        ? update(state, {
            [name]: {
              visible: { $set: false }
            }
          })
        : state;
    },
    [T.TOGGLE]: (state, action) =>
      update(state, {
        [action.payload.name]: {
          visible: { $apply: (val) => !val }
        }
      }),
    [T.RESET]: (state, action) => {
      // Resets to initialState values
      // Must be 1 level deep
      const initialSlice = initialState[action.payload.name];
      const currentSlice = state[action.payload.name];
      const extrasToUnset = [];
      if (initialSlice) {
        const updaterFromShallowCurrent = { ...currentSlice };
        Object.entries(updaterFromShallowCurrent).forEach(([key, currentValue]) => {
          const initialValue = initialSlice[key];
          const isExtra = initialValue === undefined;

          if (isExtra) {
            extrasToUnset.push(key);
            delete updaterFromShallowCurrent[key];
            return;
          }

          if (initialValue !== currentValue) {
            updaterFromShallowCurrent[key] = { $set: initialValue };
          } else {
            delete updaterFromShallowCurrent[key];
          }
        });

        if (extrasToUnset.length > 0) {
          updaterFromShallowCurrent.$unset = extrasToUnset;
        }

        return update(state, {
          [action.payload.name]: updaterFromShallowCurrent
        });
      } else {
        return state;
      }
    },
    [T.CREATE]: createModal,
    [T.DESTROY]: destroyModal
  },
  initialState
);

const reducer = (state = initialState, action) =>
  isNil(action.type) || !action.type.includes(NAME) ? state : moduleReducer(state, action);

export default Router.reducer(reducer, locationChange);
