export const experiments = {
  gamifiedRaf: {
    flag: 'gamified-raf'
  },
  spendLimitsReorder: {
    flag: 'spend-limits-reorder'
  },
  rafShareOptions: {
    flag: 'raf-share-options'
  },
  redirectReturningPlayers: {
    flag: 'redirect-returning-players'
  },
  hideSignupPromoCodeField: {
    flag: 'hide-signup-promo-code-field'
  },
  hideErrorMessageBeforeBlur: {
    flag: 'hide-error-message-before-blur'
  },
  accountMarketingPreferencesRedesign: {
    flag: 'account-marketing-preferences-redesign'
  },
  verticalMarketingPreferencesModal: {
    flag: 'vertical-marketing-preferences-modal'
  }
};
